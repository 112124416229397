import { useState } from 'react'
import type { RefundType, RoomFilters } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { HotelRoomsApollo } from './HotelRoomsApollo'
import { HotelRoomsSearchForm } from './HotelRoomsSearchForm'

export const HotelRooms = () => {
  const newHotelDetails = useFlag('newHotelDetails')
  const [availableRefunds, setAvailableRefunds] = useState<RefundType[]>([])
  const [disableOnLoading, setDisableOnLoading] = useState(false)
  const [roomFilters, setRoomFilters] = useState<RoomFilters>({
    bedType: null,
    refundable: null,
  })

  const onSetdisabledOnLoading = (isDisabled: boolean) =>
    setDisableOnLoading(isDisabled)

  const onRoomFiltersChange = (updatedRoomFilters: RoomFilters) =>
    setRoomFilters(previousRoomFilters => ({
      ...previousRoomFilters,
      ...updatedRoomFilters,
    }))

  return (
    <PageLayoutContainer>
      <section className={newHotelDetails ? '' : 'my-15'}>
        <HotelRoomsSearchForm
          availableRefunds={availableRefunds}
          disableOnLoading={disableOnLoading}
          filters={roomFilters}
          onChange={onRoomFiltersChange}
        />
        <HotelRoomsApollo
          filters={roomFilters}
          setAvailableRefundTypes={setAvailableRefunds}
          onSetdisabledOnLoading={onSetdisabledOnLoading}
        />
      </section>
    </PageLayoutContainer>
  )
}
