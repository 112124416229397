import type { RefundType, RoomFilters } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { HotelRoomsSearchControls } from './HotelRoomsSearchControls'
import { HotelRoomsSearchFormFilters } from './HotelRoomsSearchFormFilters'

interface HotelRoomsSearchFormProps {
  filters: RoomFilters
  onChange(updatedFilters: RoomFilters): void
  disableOnLoading: boolean
  availableRefunds: RefundType[]
}

export const HotelRoomsSearchForm = ({
  filters,
  onChange,
  disableOnLoading,
  availableRefunds,
}: HotelRoomsSearchFormProps) => {
  const newHotelDetails = useFlag('newHotelDetails')

  return (
    <div className={newHotelDetails ? '' : 'my-6'}>
      {!newHotelDetails && (
        <>
          <h3 className='type-h3 my-6 text-center'>Available Rooms</h3>
          <div className='grid grid-cols-1 gap-6 lg:grid-cols-3'>
            <HotelRoomsSearchControls />
          </div>
        </>
      )}
      <div className='py-5 lg:py-4'>
        <div className='flex justify-center gap-2 md:justify-start'>
          <HotelRoomsSearchFormFilters
            availableRefunds={availableRefunds}
            disableOnLoading={disableOnLoading}
            filters={filters}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  )
}
