import { useState } from 'react'
import { Button, Icon } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { Lodging } from 'src/__generated__/graphql'
import {
  HotelAmenitiesModalNew,
  PageLayoutContainer,
} from 'src/common/components'
import {
  constructAdditionalHotelAmenities,
  constructPopularHotelAmenities,
} from 'src/utils'

interface HotelAmenitiesNewProps {
  amenities: Lodging['amenities']
}

export const HotelAmenitiesNew = ({ amenities }: HotelAmenitiesNewProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const constructedPopularAmenities = () =>
    constructPopularHotelAmenities(amenities)?.map(({ icon, label }, index) => (
      <p
        key={`${label}-${index}`}
        className='type-body-1 flex items-center gap-2'
      >
        <Icon name={icon} size='small' />
        <span>{label}</span>
      </p>
    ))
  const showAmenities = !isEmpty(amenities)
  const updatedAmenities = constructAdditionalHotelAmenities(amenities)
  const constructedAdditionalAmenities = updatedAmenities?.map(
    (amenity, index) => (
      <li
        key={`${amenity}-${index}`}
        className='c-forest font-400 text-4 line-height-5 m-0 list-outside'
      >
        {amenity}
      </li>
    )
  )

  return showAmenities ? (
    <PageLayoutContainer>
      <div className='md:py-15 bg-white py-10 lg:space-y-9'>
        <h2 className='type-h2 pb-12 text-center'>Amenities</h2>
        <div className='mx-auto space-y-6 px-8'>
          <section className='gap-8.5 flex flex-col max-md:items-center md:grid md:grid-cols-[33%_67%] lg:grid-cols-[25%_75%]'>
            <div className='space-y-5 max-md:px-12 max-md:py-7'>
              <h5 className='type-h5 c-forest-light'>Popular</h5>
              <div className='space-y-2 md:space-y-3'>
                {constructedPopularAmenities()}
              </div>
            </div>
            <div className='hidden space-y-5 md:block'>
              <h6 className='type-h5 c-forest-light mb-2'>Additional</h6>
              <div className='grid grid-rows-[repeat(auto-fit,_1fr)] gap-x-5 gap-y-3 md:grid-cols-2 lg:grid-cols-3'>
                {constructedAdditionalAmenities}
              </div>
            </div>
          </section>
          <div className='mx-auto w-fit md:hidden'>
            <Button
              label='View All'
              variant='outlined'
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        </div>
        {isModalOpen && (
          <HotelAmenitiesModalNew
            amenities={amenities}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </PageLayoutContainer>
  ) : null
}
