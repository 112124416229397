import type { UnderlineTabProps } from '@travelpass/design-system'
import { Divider, UnderlineTab } from '@travelpass/design-system'
import classNames from 'classnames'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'

export const HotelStickyMenu = ({ activeIndex, tabs }: UnderlineTabProps) => {
  const newHotelDetails = useFlag('newHotelDetails')
  return (
    <div
      className={classNames(
        'of-hidden z-1 sticky',
        newHotelDetails
          ? 'top-84px md:top-100px bg-white max-md:py-3'
          : 'bg-warm-grey top-0'
      )}
    >
      <PageLayoutContainer>
        <div className='all-[.hotel-nav-buttons]:px-4 all-[.hotel-nav-buttons]:py-0.5 all-[.hotel-nav-buttons]:md:px-0 mx--4 md:mx-0'>
          <ScrollMenu scrollContainerClassName='hide-scrollbar hotel-nav-buttons'>
            <UnderlineTab
              activeIndex={activeIndex}
              tabs={tabs}
              willScroll={false}
            />
          </ScrollMenu>
        </div>
        {/** @todo we might need to add a 'showDivider' prop in 'UnderlineTab' */}
        <Divider className='-m-t-3px' />
      </PageLayoutContainer>
    </div>
  )
}
